import useResourceLegacy from 'util/resource/useResourceLegacy';
import React from 'react';
import { DocumentDownloadDTO, DocumentDTO, DocumentList } from 'types/documents';
import AttachmentPreview from './AttachmentPreview';
import { Fetched } from 'util/resource';
import { CircularProgress } from '@mui/material';
import DocumentListSummaryItem from '../../../documents/DocumentListSummaryItem';
import DocumentListItem from '../../../documents/DocumentListItem';
import styles from './styles.scss';
import PreviewableDocumentListItem from 'domain/documents/PreviewableDocumentListItem';

interface Props {
  contentId: string;
  attachmentIds: string[];
  listAttachments: (contentId: string) => Promise<DocumentList>;
  downloadAttachment: (contentId: string, documentId: string) => Promise<DocumentDownloadDTO>;
  imgCount: number;
  isPreview: boolean;
  onReadMore: () => void;
}

export default function Attachments(props: Props) {
  const [attachments] = useResourceLegacy(async () => {
    if (props.attachmentIds.length > 0) {
      return props.listAttachments(props.contentId);
    } else {
      return Promise.resolve({ documents: [] });
    }
  }, [props.attachmentIds, props.isPreview, props.contentId]);

  const documentFormatToPreview = '.pdf';
  const imageFormatsToPreview = ['.png', '.jpeg', '.jpg', '.svg', '.gif'];
  const allFormatsToPreview = [documentFormatToPreview, ...imageFormatsToPreview];

  const getAttachmentToPreview = (atchmnts: Fetched<DocumentList>): DocumentDTO | null => {
    const documentAttachmentsToPreview = atchmnts.resource.documents.filter(doc =>
      doc.name.endsWith(documentFormatToPreview),
    );

    if (documentAttachmentsToPreview.length > 0) {
      return documentAttachmentsToPreview[0];
    }

    const imageAttachmentsToPreview = atchmnts.resource.documents.filter(doc =>
      imageFormatsToPreview.some(format => doc.name.endsWith(format)),
    );

    if (imageAttachmentsToPreview.length > 0) {
      return imageAttachmentsToPreview[0];
    }

    return null;
  };

  if (attachments.state !== 'fetched') {
    return (
      <div className={styles.centerItem}>
        <CircularProgress />
      </div>
    );
  }

  const attachmentToPreview = getAttachmentToPreview(attachments);

  const previewAttachment = props.isPreview && props.imgCount == 0 && attachmentToPreview;
  const previewAttachmentCount =
    props.isPreview && props.imgCount == 0 && !attachmentToPreview && attachments.resource.documents.length > 0;
  const showAttachmentList =
    !props.isPreview && attachments.state === 'fetched' && attachments.resource.documents.length > 0;

  if (previewAttachment) {
    return <AttachmentPreview attachment={props.downloadAttachment(props.contentId, attachmentToPreview.id)} />;
  }

  if (previewAttachmentCount) {
    return (
      <DocumentListSummaryItem
        attachmentCount={attachments.resource.documents.length}
        onClick={() => props.onReadMore()}
      />
    );
  }

  return showAttachmentList ? (
    <>
      <div className={styles.attachedFilesHeader}>Attached files</div>
      {attachments.resource.documents.map(doc => {
        if (allFormatsToPreview.some(format => doc.name.endsWith(format))) {
          return (
            <PreviewableDocumentListItem
              key={doc.name}
              document={doc}
              previewType={imageFormatsToPreview.some(format => doc.name.endsWith(format)) ? 'image' : 'pdf'}
              onDownload={docId => props.downloadAttachment(props.contentId, docId)}
              iconSize="small"
            />
          );
        } else {
          return (
            <DocumentListItem
              key={doc.name}
              document={doc}
              onDownload={docId => props.downloadAttachment(props.contentId, docId)}
              iconSize="small"
            />
          );
        }
      })}
    </>
  ) : null;
}
