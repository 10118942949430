import FileIcon from '../shared/Files/File/FileIcon';
import cx from 'classnames';
import styles from '../companies/Documents/Document/styles.scss';
import React from 'react';
import styled from '@emotion/styled';
import { bluePlanetTheme } from '../../ui/theme';

interface Props {
  attachmentCount: number;
  onClick: () => void;
}

const FileContainer = styled.span`
  display: flex;
  align-items: center;
  ${bluePlanetTheme.breakpoints.down('sm')} {
    max-width: 270px;
  }
`;

export default function DocumentListSummaryItem({ attachmentCount, onClick }: Props) {
  return (
    <div className={'u-half-padding-y'}>
      <FileContainer className="u-ellipsis">
        <FileIcon name={'document'} fontSize={'small'} className="u-half-spacing-right" />
        <a onClick={onClick} className={cx(styles.document)}>
          {`${attachmentCount} attachments`}
        </a>
      </FileContainer>
    </div>
  );
}
