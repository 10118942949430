import { Author, asUserAuthor } from 'domain/shared/author';
import React, { useState } from 'react';
import { UserProfile } from 'types/user';
import EditUpdateDialog from './EditUpdateDialog';
import { getOrUndefined } from 'util/resource';
import { useSelfUserProfile } from 'apis/CompanyAPI/users/useSelfUserProfile';
import ShowUpdate from 'domain/Updates/shared/Show';
import UpdateMenu from 'pages/Rooms/Updates/Show/UpdateMenu';
import { useAuth0 } from '@auth0/auth0-react';
import { Auth0User } from 'types';
import { getAdminRights } from 'auth/util';
import useDialogHandler from 'hooks/useDialogHandler';
import useNotify from 'hooks/useNotify';
import useLazyResource from 'util/resource/useLazyResource';
import { BoardroomUpdateDTO } from './types';
import boardroomUpdateAPI from './api';
import ConfirmDialog from 'ui/modules/dialogs/ConfirmDialog';
import Button from 'ui/elements/buttons/Button';

interface Props {
  className?: string;
  boardroomUpdate: BoardroomUpdateDTO;
  companyId: number;
  creator?: UserProfile;
  author?: Author;
  onDelete?: () => void;
  canEditPost: boolean;
  onChange: () => void;
  headingActions?: JSX.Element;
}

export type ViewType = 'preview' | 'comment' | 'read more';

export default function Update(props: Props) {
  const notify = useNotify();
  const { boardroomUpdate, creator } = props;
  const [editUpdate, setEditUpdate] = useState(false);
  const { resource: userProfileResource } = useSelfUserProfile();
  const userProfile = getOrUndefined(userProfileResource);
  const [viewType, setViewType] = useState<ViewType>('preview');
  const deleteDialogHandler = useDialogHandler();

  const api = boardroomUpdateAPI(props.boardroomUpdate.companyId);

  const [onDelete, isDeleting] = useLazyResource(() => api.delete(boardroomUpdate.update.id), {
    onSuccess: () => {
      notify('success', 'Post deleted!');
      deleteDialogHandler.close();
      props.onChange();
    },
    onFailure: () => {
      notify('error', 'Something went wrong when trying to delete post');
    },
  });

  const isAuthor = creator?.cwUserId === userProfile?.cwUserId;

  const { user }: { user?: Auth0User } = useAuth0();
  const adminRights = getAdminRights(user);
  const canAccessBackoffice = adminRights.includes('backoffice');

  return (
    <>
      <ShowUpdate
        className={props.className}
        api={api}
        update={props.boardroomUpdate.update}
        author={props.author ?? asUserAuthor(creator)}
        creator={props.creator}
        viewType={viewType}
        canEditUpdate={props.canEditPost}
        updateContext={{ type: 'boardroom', companyId: props.companyId }}
        actions={
          isAuthor || canAccessBackoffice ? (
            <>
              <UpdateMenu
                onEdit={isAuthor ? () => setEditUpdate(true) : undefined}
                onDelete={canAccessBackoffice ? deleteDialogHandler.open : undefined}
              />
              {props.headingActions}
            </>
          ) : null
        }
      />
      {editUpdate && (
        <EditUpdateDialog
          setActive={setEditUpdate}
          boardroomUpdate={props.boardroomUpdate}
          user={userProfile}
          onComplete={() => {
            props.onChange();
            setViewType('read more');
          }}
        />
      )}
      <ConfirmDialog
        isOpen={deleteDialogHandler.isOpen}
        onClose={deleteDialogHandler.close}
        title="You are about to delete this post"
        confirmButton={
          <Button color="red" kind="primary" onClick={onDelete} isLoading={isDeleting}>
            Yes, delete
          </Button>
        }
        dismissLabel="No, cancel"
      >
        <p className="u-content-spacing-bottom">It will no longer be visible anywhere on the platform.</p>
        <p>Are you sure?</p>
      </ConfirmDialog>
    </>
  );
}
