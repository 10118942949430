import React from 'react';
import { getOrUndefined } from 'util/resource';
import { bluePlanetTheme } from 'ui/theme';
import { useCompanyUserSummary } from 'apis/CompanyAPI/companies/useCompanyUserSummary';
import Todos from './todos/Todos';
import { CompanyListItem, CompanyReachDTO } from 'types/company';
import CompanySelector from '../sections/CompanySelector';
import { communityUrls, companyUrls } from 'urls';
import CompanyInsights from './CompanyInsightsCards';
import InviteUserIcon from 'ui/elements/icons/InviteUserIcon';
import { companiesCommunitiesByRole } from 'domain/companies/companyUtils';
import useLocalStorage from 'hooks/useLocalStorage';
import { useCompanyOrCommunityDashboardData } from '../useDashboardData';
import GraphIcon from 'ui/elements/icons/GraphIcon';
import TurnRightIcon from 'ui/elements/icons/TurnRightIcon';
import OnYourCompanyAgenda from './OnYourCompanyAgenda';
import { MyCommunity, TinyCommunity } from 'types/company/community';
import CompaniesIcon from 'ui/elements/icons/CompanyIcon';
import UserIcon from 'ui/elements/icons/UserIcon';
import LinkAsButton from 'ui/elements/buttons/LinkAsButton';
import AddIcon from 'ui/elements/icons/AddIcon';
import styled from '@emotion/styled';
import { halfSpacing, sectionSpacing } from 'ui/theme/themeConstants';
import Card from 'ui/views/cards/Card';
import { Link } from 'react-router-dom';
import useResource from 'util/resource/useResource';
import { getReachText } from 'pages/Company/Insights';
import { companiesAPIUrls } from 'apis/CompanyAPI/companies/companiesApi';
import Resource from 'util/resource/Resource';
import FounderPulse from './FounderPulse';
import { Hidden } from '@mui/material';
import CompanyInsightsSidebar from './CompanyInsightsSidebar';
import TrendingUpIcon from 'ui/elements/icons/TrendingUpIcon';

export const NUMBER_OF_DAYS_IN_INSIGHTS_PERIOD = 30;

const dashboardContextKey = 'dashboard-context';

const ActionLinks = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${halfSpacing};

  ${bluePlanetTheme.breakpoints.down('sm')} {
    flex-direction: column;
  }
`;

const Grid = styled.div`
  display: grid;
  column-gap: ${sectionSpacing};
  grid-template-columns: 1fr 300px;
  grid-template-areas: 'main sidebar';
  ${bluePlanetTheme.breakpoints.down('lg')} {
    grid-template-columns: 1fr;
  }
`;

export function Manage({
  companies,
  communities,
}: {
  companies: CompanyListItem[];
  communities: MyCommunity[];
}): React.ReactNode {
  const byRoles = companiesCommunitiesByRole(companies, communities);
  const managements = [...byRoles.companiesIManage, ...byRoles.communitiesIManage.map(c => c.details)];

  const [selected, setSelectedCompanyOrCommunity] = useLocalStorage<CompanyListItem | TinyCommunity | undefined>(
    dashboardContextKey,
    undefined,
  );
  const selectedCompanyOrCommunity = managements.some(it => it.id === selected?.id) ? selected : managements[0];

  const companyUserSummary = useCompanyUserSummary(selectedCompanyOrCommunity?.id);
  const companyUsers = getOrUndefined(companyUserSummary.resource);

  const { resource: dashboardData } = useCompanyOrCommunityDashboardData(selectedCompanyOrCommunity);
  const { resource: reachResource } = useResource<CompanyReachDTO>(
    selectedCompanyOrCommunity && !selectedCompanyOrCommunity.isCommunity
      ? companiesAPIUrls.insights.reach(selectedCompanyOrCommunity.id)
      : undefined,
  );

  const reach = getOrUndefined(reachResource);

  return (
    <>
      {managements.length > 1 && selectedCompanyOrCommunity && (
        <div className="u-half-spacing-bottom">
          <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: bluePlanetTheme.spacing(1) }}>
            <CompanySelector
              value={selectedCompanyOrCommunity}
              onSelect={value => setSelectedCompanyOrCommunity(value)}
              values={managements}
            />
          </div>
        </div>
      )}
      <Resource resource={dashboardData}>
        {dashboardData => (
          <>
            <div>
              {selectedCompanyOrCommunity?.id != null &&
                (!selectedCompanyOrCommunity?.isCommunity ? (
                  <Card className="u-section-spacing-bottom">
                    {reach && (
                      <div className="text-metadata u-flex-align-center u-flex--gap-1 u-content-spacing-bottom u-flex--wrap">
                        <span>
                          Company connections <strong className="text-weight-medium">{reach.connectionCount}</strong>
                        </span>
                        <span>
                          Potential reach{' '}
                          <strong>
                            {reach.potentialReach ? (
                              <strong className="text-weight-medium">{getReachText(reach.potentialReach)}</strong>
                            ) : (
                              <strong className="text-weight-medium">{reach.connectionCount}</strong>
                            )}
                          </strong>
                        </span>
                        <span>
                          <Link
                            className="text-link-small"
                            to={companyUrls.settings.inviteUser(selectedCompanyOrCommunity.slug)}
                          >
                            Invite shareholders and followers
                          </Link>{' '}
                          or{' '}
                          <Link
                            className="text-link-small"
                            to={companyUrls.settings.share(selectedCompanyOrCommunity.slug)}
                          >
                            share your profile
                          </Link>
                        </span>
                      </div>
                    )}
                    <ActionLinks>
                      <LinkAsButton
                        url={companyUrls.overview(selectedCompanyOrCommunity.slug, 'updates', {
                          isPostingUpdate: true,
                        })}
                      >
                        <AddIcon className="u-half-spacing-right" fontSize="small"></AddIcon>
                        New post
                      </LinkAsButton>
                      <LinkAsButton
                        color="greyLight"
                        url={companyUrls.settings.inviteUser(selectedCompanyOrCommunity.slug)}
                      >
                        <InviteUserIcon className="u-half-spacing-right" fontSize="small" /> Invite connections
                      </LinkAsButton>
                      <LinkAsButton color="greyLight" url={companyUrls.kpi.manage(selectedCompanyOrCommunity.slug)}>
                        <GraphIcon className="u-half-spacing-right" fontSize="small" />
                        Update metrics
                      </LinkAsButton>
                      <LinkAsButton
                        color="greyLight"
                        url={companyUrls.overview(selectedCompanyOrCommunity.slug, 'profile')}
                      >
                        <TurnRightIcon className="u-half-spacing-right" fontSize="small" />
                        Go to company profile
                      </LinkAsButton>
                      <LinkAsButton
                        color="greyLight"
                        url={companyUrls.settings.fundingRound(selectedCompanyOrCommunity.slug)}
                      >
                        <TrendingUpIcon className="u-half-spacing-right" fontSize="small" />
                        Start funding round
                      </LinkAsButton>
                    </ActionLinks>
                  </Card>
                ) : (
                  <Card className="u-section-spacing-bottom">
                    <ActionLinks>
                      <LinkAsButton
                        url={communityUrls.overview(selectedCompanyOrCommunity.slug, 'updates', {
                          isPostingUpdate: true,
                        })}
                      >
                        <AddIcon className="u-half-spacing-right" />
                        New post
                      </LinkAsButton>
                      <LinkAsButton
                        color="greyLight"
                        url={communityUrls.manageMembers.companies(selectedCompanyOrCommunity.slug)}
                      >
                        <CompaniesIcon className="u-half-spacing-right" fontSize="small" />
                        Manage companies
                      </LinkAsButton>
                      <LinkAsButton
                        color="greyLight"
                        url={communityUrls.manageMembers.users(selectedCompanyOrCommunity.slug)}
                      >
                        <UserIcon className="u-half-spacing-right" fontSize="small" />
                        Manage users
                      </LinkAsButton>
                      <LinkAsButton
                        color="greyLight"
                        url={communityUrls.manageMembers.inviteCompany(selectedCompanyOrCommunity.slug)}
                      >
                        <InviteUserIcon className="u-half-spacing-right" fontSize="small" />
                        Invite members
                      </LinkAsButton>
                      <LinkAsButton color="greyLight" url={communityUrls.overview(selectedCompanyOrCommunity.slug)}>
                        <TurnRightIcon className="u-half-spacing-right" fontSize="small" />
                        Go to community
                      </LinkAsButton>
                    </ActionLinks>
                  </Card>
                ))}
            </div>
            <div>
              <OnYourCompanyAgenda
                messages={dashboardData.messages.filter(
                  it =>
                    ![
                      'welcome-to-company-profile-as-new-company-admin',
                      'should-update-investors-as-company-admin',
                      'should-update-company-profile-as-company-admin',
                    ].includes(it.type),
                )} // We show these in to-do's instead
              />
            </div>
            {selectedCompanyOrCommunity?.isCommunity === false && (
              <CompanyInsights company={selectedCompanyOrCommunity} />
            )}
            {selectedCompanyOrCommunity?.isCommunity === false && (
              <Grid>
                <div style={{ gridArea: 'main' }}>
                  {companyUsers && (
                    <Todos
                      messages={dashboardData.messages}
                      userSummary={companyUsers}
                      company={selectedCompanyOrCommunity}
                    />
                  )}
                  <div></div>
                  <FounderPulse company={selectedCompanyOrCommunity} />
                </div>
                <Hidden lgDown>
                  <div style={{ gridArea: 'sidebar' }}>
                    <CompanyInsightsSidebar company={selectedCompanyOrCommunity} />
                  </div>
                </Hidden>
              </Grid>
            )}
          </>
        )}
      </Resource>
    </>
  );
}
