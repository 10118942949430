import { DocumentDownloadDTO, DocumentDTO } from '../../types/documents';
import React, { useEffect } from 'react';
import PitchDeckPDF from '../companies/Documents/Document/PitchDeck/PitchDeckPDF';
import DocumentListItem from './DocumentListItem';
import ImageDialog from './ImageDialog';

interface Props {
  href?: string;
  className?: string;
  iconSize?: 'small' | 'large';
  previewType: 'pdf' | 'image';
  children?: JSX.Element;
  document: DocumentDTO;
  highlightUnread?: boolean;
  showTimestamp?: boolean;
  onDownload: (documentId: UUID) => Promise<DocumentDownloadDTO>;
}

export default function PreviewableDocumentListItem(props: Props) {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [dialogPageNumber, setDialogPageNumber] = React.useState<number | null>(null);
  const [downloadedDocument, setDownloadedDocument] = React.useState<DocumentDownloadDTO | null>(null);

  useEffect(() => {
    const setDownloaded = async () => {
      const downloadedDoc = await props.onDownload(props.document.id);
      setDownloadedDocument(downloadedDoc);
    };

    setDownloaded();
  }, [dialogOpen]);

  return (
    <>
      <DocumentListItem
        href={props.href}
        key={props.document.name}
        onDownload={props.onDownload}
        className={props.className}
        iconSize={props.iconSize}
        document={props.document}
        highlightUnread={props.highlightUnread}
        showTimestamp={props.showTimestamp}
        onFileNameClick={() => setDialogOpen(true)}
      >
        {props.children}
      </DocumentListItem>
      {dialogOpen &&
        (props.previewType === 'pdf' ? (
          <PitchDeckPDF
            pitchDeckPath={downloadedDocument?.path ?? ''}
            onClose={() => setDialogOpen(false)}
            pageNumber={dialogPageNumber ?? 1}
            onPageChange={num => setDialogPageNumber(num)}
          />
        ) : (
          <ImageDialog imagePath={downloadedDocument?.path ?? ''} onClose={() => setDialogOpen(false)} />
        ))}
    </>
  );
}
