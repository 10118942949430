import React from 'react';
import BoostFundingRoundButton from '../BoostFundingRoundButton';

export default function InvestorMatching({ companyId }: { companyId: number }) {
  return (
    <div className="u-flex u-flex--column u-flex--gap-half">
      <h4>Investor matching</h4>
      <span>
        We will distribute your company pitch to investors in our network looking for companies just like yours. Based
        on the data you have provided, we can match you with a relevant selection of investors who will review your case
        and follow you if they are interested.
      </span>
      <BoostFundingRoundButton
        tag={`wants-investor-matching-${companyId}`}
        feedback="InterestedInInvestorMatchingFundingRound"
      />
    </div>
  );
}
