import { DocumentDownloadDTO } from 'types/documents';
import React, { useRef, useState } from 'react';
import useContainerWidth from '../../../../hooks/useContainerWidth';
import { Document, Thumbnail } from 'react-pdf';
import CircularProgress from '@mui/material/CircularProgress';
import styles from '../../../companies/Documents/Document/PitchDeck/styles.scss';
import cx from 'classnames';
import ChevronLeftIcon from '../../../../ui/elements/icons/ChevronLeftIcon';
import ChevronRightIcon from '../../../../ui/elements/icons/ChevronRightIcon';
import PitchDeckPDF from '../../../companies/Documents/Document/PitchDeck/PitchDeckPDF';
import { useMediaQuery } from '@mui/material';
import { bluePlanetTheme } from '../../../../ui/theme';
import IconButton from '../../../../ui/elements/icons/IconButton';
import ExpandIcon from '../../../../ui/elements/icons/ExpandIcon';
import DownloadIcon from '../../../../ui/elements/icons/DownloadIcon';
import { downloadBlob } from 'domain/shared/Files/actions';
import updateStyles from './styles.scss';
import useSwipe from 'hooks/useSwipe';
import useDownloadWarning from 'domain/documents/useDownloadWarning';

interface PdfPreviewProps {
  document: DocumentDownloadDTO;
}

export default function PdfPreview({ document }: PdfPreviewProps) {
  const [dialogPageNumber, setDialogPageNumber] = useState<number | null>(null);
  const [numPages, setNumPages] = useState<number | null>(null);
  const [currentPageNum, setCurrentPageNum] = useState<number>(1);
  const isMobile = useMediaQuery(bluePlanetTheme.breakpoints.down('sm'));
  const mainDivRef = useRef<HTMLDivElement>(null);
  const mainDivWidth = useContainerWidth(mainDivRef, [document.path, numPages]);
  const mainThumbnailWidth = !isMobile ? (mainDivWidth ? mainDivWidth / 2 : mainDivWidth) : mainDivWidth;

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
  }

  const [isLeftNavigationHighlighted, setIsLeftNavigationHighlighted] = useState(false);
  const [isRightNavigationHighlighted, setIsRightNavigationHighlighted] = useState(false);
  const [isThumbnailHighlighted, setIsThumbnailHighlighted] = useState(false);

  const handleLeftNavigationPointerMove = () => {
    if (!isLeftNavigationHighlighted) {
      setIsLeftNavigationHighlighted(true);
      setIsRightNavigationHighlighted(false);
    }
  };

  const handleRightNavigationPointerMove = () => {
    if (!isRightNavigationHighlighted) {
      setIsRightNavigationHighlighted(true);
      setIsLeftNavigationHighlighted(false);
    }
  };

  const handleSwipeLeft = () => {
    if (currentPageNum < (numPages ?? 1)) {
      setCurrentPageNum(currentPageNum + 1);
    }
  };

  const handleSwipeRight = () => {
    if (currentPageNum > 1) {
      setCurrentPageNum(currentPageNum - 1);
    }
  };

  const swipeHandlers = useSwipe({
    onSwipedRight: handleSwipeRight,
    onSwipedLeft: handleSwipeLeft,
  });

  const preloadHiddenNextPage = (page: number) => {
    return (
      <div hidden={true}>
        <Thumbnail pageNumber={page} />
      </div>
    );
  };

  const { onClick: onDownloadClick, WarningDialog } = useDownloadWarning(document.filename, () =>
    downloadBlob(document),
  );

  return (
    <>
      <div
        ref={mainDivRef}
        className={`u-flex ${!isMobile ? 'u-quarter-padding-y' : ''}`}
        style={{
          borderRadius: bluePlanetTheme.shape.borderRadius,
          backgroundColor: bluePlanetTheme.bluePlanetPalette.grey.light,
        }}
      >
        {isMobile && (
          <div {...swipeHandlers} style={{ flexBasis: mainThumbnailWidth }}>
            <Document
              file={document.path}
              onLoadSuccess={onDocumentLoadSuccess}
              loading={
                <div className={updateStyles.centerItem}>
                  <CircularProgress />
                </div>
              }
            >
              <Thumbnail
                pageNumber={currentPageNum}
                width={mainThumbnailWidth}
                onItemClick={() => setDialogPageNumber(currentPageNum)}
                className={styles.mainPreview}
              />
              {currentPageNum + 1 <= (numPages ?? 0) && preloadHiddenNextPage(currentPageNum + 1)}
            </Document>
          </div>
        )}

        {!isMobile && (
          <>
            <div
              className="u-half-padding-x"
              style={{
                flexBasis: mainThumbnailWidth ? mainThumbnailWidth / 2 : 'auto',
                fontFamily: bluePlanetTheme.typography.fontFamily,
                ...bluePlanetTheme.typography.body1,
                color: bluePlanetTheme.palette.grey['600'],
              }}
            >
              {`Page ${currentPageNum}/${numPages}`}
            </div>
            <div style={{ flexBasis: mainThumbnailWidth ? mainThumbnailWidth : 'auto' }}>
              <Document
                file={document.path}
                onLoadSuccess={onDocumentLoadSuccess}
                loading={
                  <div className={updateStyles.centerItem}>
                    <CircularProgress />
                  </div>
                }
              >
                <div
                  key={`page_${currentPageNum}`}
                  onPointerEnter={() => setIsThumbnailHighlighted(true)}
                  onPointerLeave={() => setIsThumbnailHighlighted(false)}
                >
                  <Thumbnail
                    pageNumber={currentPageNum}
                    width={mainThumbnailWidth}
                    onItemClick={() => setDialogPageNumber(currentPageNum)}
                    className={styles.mainPreview}
                  >
                    <div
                      className={cx(styles.navigateLeft, {
                        [styles.highlighted]: isLeftNavigationHighlighted,
                        [styles.hidden]: (currentPageNum && currentPageNum < 2) || !isThumbnailHighlighted,
                      })}
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        setCurrentPageNum(currentPageNum - 1);
                      }}
                      onPointerEnter={() => setIsLeftNavigationHighlighted(true)}
                      onPointerLeave={() => setIsLeftNavigationHighlighted(false)}
                      onPointerMove={handleLeftNavigationPointerMove}
                    >
                      <div className={styles.navigateArrow}>
                        <ChevronLeftIcon />
                      </div>
                    </div>
                    <div
                      className={cx(styles.navigateRight, {
                        [styles.highlighted]: isRightNavigationHighlighted,
                        [styles.hidden]:
                          (currentPageNum && numPages && currentPageNum >= numPages) || !isThumbnailHighlighted,
                      })}
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        setCurrentPageNum(currentPageNum + 1);
                      }}
                      onPointerEnter={() => setIsRightNavigationHighlighted(true)}
                      onPointerLeave={() => setIsRightNavigationHighlighted(false)}
                      onPointerMove={handleRightNavigationPointerMove}
                    >
                      <div className={styles.navigateArrow}>
                        <ChevronRightIcon />
                      </div>
                    </div>
                  </Thumbnail>
                  {currentPageNum + 1 <= (numPages ?? 0) && preloadHiddenNextPage(currentPageNum + 1)}
                </div>
              </Document>
            </div>
            <div
              className={'u-flex u-half-padding-x u-flex-end'}
              style={{
                flexBasis: mainThumbnailWidth ? mainThumbnailWidth / 2 : 'auto',
              }}
            >
              <div className={'u-flex u-flex--column u-flex-space-between'}>
                <IconButton onClick={() => setDialogPageNumber(currentPageNum)}>
                  <ExpandIcon
                    style={{
                      color: bluePlanetTheme.bluePlanetPalette.grey.dark,
                    }}
                  />
                </IconButton>
                <IconButton onClick={onDownloadClick}>
                  <DownloadIcon
                    style={{
                      color: bluePlanetTheme.bluePlanetPalette.grey.dark,
                    }}
                  />
                </IconButton>
              </div>
            </div>
          </>
        )}
      </div>
      {WarningDialog}
      {dialogPageNumber && (
        <PitchDeckPDF
          pitchDeckPath={document.path}
          onClose={() => setDialogPageNumber(null)}
          pageNumber={dialogPageNumber}
          onPageChange={num => setCurrentPageNum(num)}
        />
      )}
    </>
  );
}
