import CircularProgress from '@mui/material/CircularProgress';
import cx from 'classnames';
import React from 'react';
import FileIcon from 'domain/shared/Files/File/FileIcon';
import Timestamp from 'ui/elements/Timestamp';
import Tooltip from 'ui/elements/Tooltip';
import styles from 'domain/companies/Documents/Document/styles.scss';
import { DocumentDownloadDTO, DocumentDTO } from 'types/documents';
import styled from '@emotion/styled';
import { bluePlanetTheme } from 'ui/theme';
import useNotify from '../../hooks/useNotify';
import useLazyResource from '../../util/resource/useLazyResource';
import { downloadBlob } from '../shared/Files/actions';
import useDownloadWarning from './useDownloadWarning';
import IconButton from '../../ui/elements/icons/IconButton';
import DownloadIcon from '../../ui/elements/icons/DownloadIcon';

const FileContainer = styled.span`
  display: flex;
  align-items: center;
  ${bluePlanetTheme.breakpoints.down('sm')} {
    max-width: 270px;
  }
`;

function Text({ document, highlightUnread }: { document: DocumentDTO; highlightUnread?: boolean }) {
  return (
    <Tooltip className="u-ellipsis" title={document.name}>
      <span className={cx({ 'text-medium text-weight-medium': !document.personalization?.hasRead && highlightUnread })}>
        {document.name}
      </span>
    </Tooltip>
  );
}

interface DocumentListItemProps {
  href?: string;
  className?: string;
  iconSize?: 'small' | 'large';
  children?: JSX.Element;
  document: DocumentDTO;
  highlightUnread?: boolean;
  showTimestamp?: boolean;
  onFileNameClick?: () => void;
  onDownload: (documentId: UUID) => Promise<DocumentDownloadDTO>;
  onDownloadSuccess?: () => void;
}

export default function DocumentListItem(props: DocumentListItemProps) {
  const notify = useNotify();
  const [downloadDocument, isDownloading] = useLazyResource(
    (documentId: string) => props.onDownload(documentId).then(response => downloadBlob(response)),
    {
      onSuccess: () => {
        props.onDownloadSuccess && props.onDownloadSuccess();
      },
      onFailure: () => notify('error', 'Could not download the document.'),
    },
  );

  const { onClick, WarningDialog } = useDownloadWarning(props.document.name, () => downloadDocument(props.document.id));

  return (
    <div className={cx(props.className, styles.listItem)}>
      <div
        className={'u-flex-grow u-quarter-padding'}
        style={{
          backgroundColor: bluePlanetTheme.bluePlanetPalette.grey.light,
          borderRadius: bluePlanetTheme.shape.borderRadius,
        }}
      >
        {!isDownloading ? (
          <div className="u-flex u-flex-align-center u-flex-space-between">
            <FileContainer className="u-ellipsis">
              <FileIcon
                name={props.document.name}
                fontSize={props.iconSize || 'large'}
                className="u-half-spacing-right"
              />
              <a onClick={props.onFileNameClick ?? onClick} className={cx(styles.document)} href={props.href}>
                <Text document={props.document} highlightUnread={props.highlightUnread} />
              </a>
            </FileContainer>
            {props.showTimestamp && (
              <Timestamp
                className="text-metadata u-half-spacing-left"
                style={{ flexShrink: 0 }}
                timestamp={props.document.createdAt}
              />
            )}
            <IconButton onClick={onClick}>
              <DownloadIcon
                style={{
                  color: bluePlanetTheme.bluePlanetPalette.grey.dark,
                }}
              />
            </IconButton>
          </div>
        ) : (
          <div className="u-flex u-flex-align-center u-flex-space-between">
            <span className="u-ellipsis u-flex u-flex-align-center">
              <CircularProgress className="u-half-spacing-right" size={props.iconSize === 'small' ? 20 : 35} />
              <Text document={props.document} highlightUnread={props.highlightUnread} />
            </span>
            {props.showTimestamp && (
              <Timestamp
                className="u-half-spacing-left"
                style={{ flexShrink: 0 }}
                timestamp={props.document.createdAt}
              />
            )}
          </div>
        )}
      </div>
      {props.children}
      {WarningDialog}
    </div>
  );
}
