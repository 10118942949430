import { asUserAuthor, AuthorImage, nameOf } from 'domain/shared/author';
import React, { useState, useEffect } from 'react';
import { CompanyListItem, ICompany } from 'types/company';
import TextField from 'ui/elements/form/TextField';
import Card from 'ui/views/cards/Card';
import CardStack from 'ui/views/cards/CardStack';
import UpdateForm from '../UpdateForm';
import { getOrUndefined } from 'util/resource';
import { useHistory, useLocation } from 'react-router-dom';
import useNotify from 'hooks/useNotify';
import { useSelfUserProfile } from 'apis/CompanyAPI/users/useSelfUserProfile';
import { TinyCommunity } from 'types/company/community';
import { userDashboardKey } from 'pages/Dashboard/useUserDashboardData';
import { invalidate } from 'hooks/useSWR';
import { companyOnboardingKey } from 'apis/OnboardingAPI';
import useLazyResource from 'util/resource/useLazyResource';
import { communityUpdateAPI, companyUpdateAPI } from 'apis/ContentAPI/UpdateAPI';
import { CommunityUpdate, PostUpdateTo } from 'types/content';
import { CompanyUpdateFormValues } from 'domain/Updates/CompanyUpdate/UpdateForm/types';

interface Props {
  initiallyOpen?: boolean;
  title: string;
  companies: (CompanyListItem | TinyCommunity)[];
  company: ICompany;
  onUpdatePosted: (result?: CommunityUpdate) => void;
}

export default function PostUpdateDialog(props: Props) {
  const location = useLocation();
  const history = useHistory();
  const notify = useNotify();
  const queryParams = new URLSearchParams(location.search);

  function removeUrlParam(param: string) {
    if (queryParams.has(param)) {
      queryParams.delete(param);
      history.replace({
        search: queryParams.toString(),
      });
    }
  }

  const [isActive, setActive] = useState(false);
  useEffect(() => {
    if (props.initiallyOpen) {
      setActive(true);
    }
  }, [props.initiallyOpen]);

  const onClose = () => {
    setActive(false);

    // To make the checklist on the company page open the update dialog after closing it, we need to reset the url
    removeUrlParam('writeUpdate');
  };

  const { resource: userProfileResource } = useSelfUserProfile();
  const userProfile = getOrUndefined(userProfileResource);

  const author = asUserAuthor(userProfile);

  type postResult = { type: 'companyUpdate' } | { type: 'communityUpdate'; update: CommunityUpdate };

  const [createUpdate, isSaving] = useLazyResource<CompanyUpdateFormValues & { postTo: PostUpdateTo }, postResult>(
    dto => {
      if (dto.postTo.type === 'company') {
        const updatesAPI = companyUpdateAPI(props.company.id);
        return updatesAPI.create({ ...dto, visibility: dto.postTo.visibility }).then(() => ({ type: 'companyUpdate' }));
      } else {
        const api = communityUpdateAPI(dto.postTo.community.id);
        return api
          .create({
            ...dto,
            postedBy: { type: 'company', companyId: props.company.id },
            visibility: 'CommunityMembers',
          })
          .then(update => ({ type: 'communityUpdate', update }));
      }
    },
    {
      onSuccess: result => {
        notify('success', 'Update posted!');
        onClose();
        props.onUpdatePosted(result.type === 'communityUpdate' ? result.update : undefined);
        invalidate(userDashboardKey);
        invalidate(companyOnboardingKey(props.company.slug));
      },
      onFailure: () => {
        notify('error', `Something went wrong when trying to post update`);
      },
    },
  );

  if (isActive) {
    return (
      <UpdateForm
        dialogTitle={props.title}
        author={author}
        selectedVisibility="Public"
        onSave={(dto, postTo) => createUpdate({ ...dto, postTo })}
        isSaving={isSaving}
        onClose={onClose}
        company={props.company}
      />
    );
  }

  return (
    <CardStack>
      <Card className="u-flex-space-between">
        <span className="text-medium text-weight-medium">{props.title}</span>
      </Card>
      <Card>
        <div className="u-flex">
          <AuthorImage author={author} />
          <TextField
            onFocus={() => setActive(true)}
            placeholder={`What would you like to share, ${nameOf(author)?.split(' ')[0]}?`}
            sx={{
              '.MuiInput-root': {
                borderColor: 'transparent',
              },
            }}
          />
        </div>
      </Card>
    </CardStack>
  );
}
