import { useMediaQuery } from '@mui/material';
import { FounderPulseDTO, FounderPulseItem } from 'apis/CompanyAPI';
import { useCommunities } from 'apis/CompanyAPI/communities/useCommunities';
import { companiesAPIUrls } from 'apis/CompanyAPI/companies/companiesApi';
import React from 'react';
import { ICompany } from 'types/company';
import { MyCommunity } from 'types/company/community';
import { getAnonymousUserTitle } from 'types/user';
import Logo from 'ui/domain/Logo';
import SectionHeadingLarge from 'ui/elements/SectionHeading/SectionHeadingLarge';
import Avatar from 'ui/elements/avatars/Avatar';
import Button from 'ui/elements/buttons/Button';
import IconAvatar from 'ui/elements/icons/IconAvatar';
import InfoIcon from 'ui/elements/icons/InfoIcon';
import UserIcon from 'ui/elements/icons/UserIcon';
import { bluePlanetTheme } from 'ui/theme';
import CardStack from 'ui/views/cards/CardStack';
import ClickableCard from 'ui/views/cards/ClickableCard';
import { MobileMargin } from 'ui/views/layouts/BaseLayout';
import InfoMessageInline from 'ui/views/messages/InfoMessageInline';
import { communityUrls, companyUrls } from 'urls';
import { timeSince } from 'util/dateUtils';
import { getOrElse } from 'util/resource';
import Resource from 'util/resource/Resource';
import useResource from 'util/resource/useResource';
import { truncate } from 'util/stringUtils';

const LOGO_SIZE = 40;

export function getTitleAndHref(
  company: ICompany,
  communities: MyCommunity[],
  item: FounderPulseItem,
  isTabletDown: boolean,
): {
  title: React.ReactNode;
  href: string;
  logo: React.ReactNode;
} | null {
  const maxUpdateTitleLength = isTabletDown ? 16 : 45;
  const community =
    item.type === 'Commented' || item.type === 'Liked'
      ? communities.find(c => c.id === item.update.postedInCommunityId)
      : undefined;
  switch (item.type) {
    case 'Commented':
      return {
        title: `${item.user.name} commented on the post «${truncate(item.update.title, maxUpdateTitleLength)}»`,
        href: community
          ? communityUrls.update.view(community.slug, item.update.id)
          : companyUrls.update.view(company.slug, item.update.id),
        logo: <Avatar size={LOGO_SIZE} imageUrl={item.user.imageUrl}></Avatar>,
      };
    case 'Liked':
      return {
        title: `${item.user.name} liked the post «${truncate(item.update.title, maxUpdateTitleLength)}»`,
        href: community
          ? communityUrls.update.view(community.slug, item.update.id)
          : companyUrls.update.view(company.slug, item.update.id),
        logo: <Avatar size={LOGO_SIZE} imageUrl={item.user.imageUrl}></Avatar>,
      };
    case 'Followed':
      return {
        title: `${item.user.name} started following your company`,
        href: companyUrls.overview(company.slug, 'users', { connectionsRoleFilter: 'prospective_investor' }),
        logo: <Avatar size={LOGO_SIZE} imageUrl={item.user.imageUrl}></Avatar>,
      };
    case 'JoinedCommunity':
      return {
        title: `Your company joined ${item.community.name}`,
        href: communityUrls.overview(item.community.slug),

        logo: <Logo size={LOGO_SIZE} url={item.community.logoURL} type="community"></Logo>,
      };
    case 'Shortlisted':
      return {
        title: `${getAnonymousUserTitle(item.user)} added your company to their collection`,
        href: companyUrls.insights(company.slug),

        logo: (
          <IconAvatar color="blue" size={{ width: LOGO_SIZE + 'px', height: LOGO_SIZE + 'px' }}>
            <UserIcon />
          </IconAvatar>
        ),
      };
    case 'PitchDeckDownloaded':
      return {
        title: `${getAnonymousUserTitle(item.user)} downloaded your pitch deck`,
        href: companyUrls.insights(company.slug),
        logo: (
          <IconAvatar color="blue" size={{ width: LOGO_SIZE + 'px', height: LOGO_SIZE + 'px' }}>
            <UserIcon />
          </IconAvatar>
        ),
      };
    case 'CompanyMessagedReceived':
      return {
        title: `${item.user.name} sent you a message`,
        href: companyUrls.inbox.view(company.slug, item.user.cwUserId),
        logo: <Avatar size={LOGO_SIZE} imageUrl={item.user.imageUrl}></Avatar>,
      };

    default:
      return null;
  }
}

interface Props {
  company: ICompany;
}
const PULSE_PAGE_SIZE = 30;

export default function FounderPulse({ company }: Props) {
  const { resource: founderPulse } = useResource<FounderPulseDTO>(companiesAPIUrls.founder.pulse(company.id));
  const [numberOfVisibleItems, setNumberOfVisibleItems] = React.useState(PULSE_PAGE_SIZE);
  const communitiesResource = useCommunities();
  const communities = getOrElse(communitiesResource.resource, { values: [] }).values;

  const isTabletDown = useMediaQuery(bluePlanetTheme.breakpoints.down('md'));

  return (
    <div>
      <MobileMargin>
        <SectionHeadingLarge heading="Latest activity" />
      </MobileMargin>
      <Resource resource={founderPulse}>
        {founderPulse => (
          <>
            <CardStack>
              {founderPulse.values.slice(0, numberOfVisibleItems).map((item, i) => {
                const values = getTitleAndHref(company, communities, item, isTabletDown);
                const image = values?.logo ?? '';
                return (
                  values && (
                    <ClickableCard
                      avatar={typeof image === 'string' ? <Logo size={40} url={image} type="company" /> : image}
                      title={values.title}
                      key={i}
                      href={values.href}
                    >
                      <span className="text-metadata">{timeSince(item.timestamp)}</span>
                    </ClickableCard>
                  )
                );
              })}
            </CardStack>
            {numberOfVisibleItems < founderPulse.values.length && (
              <Button
                className="u-half-spacing"
                kind="tertiary"
                onClick={() => setNumberOfVisibleItems(numberOfVisibleItems + 20)}
              >
                See more
              </Button>
            )}
            {founderPulse.values.length < 1 && (
              <InfoMessageInline
                className="u-half-spacing-top u-half-padding"
                icon={<InfoIcon></InfoIcon>}
                color="blue"
              >
                No recent activity. Staying active by updating your company profile and posting updates can help you get
                more engagement.
              </InfoMessageInline>
            )}
          </>
        )}
      </Resource>
    </div>
  );
}
